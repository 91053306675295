<template>
	<div class="container">

		<Header></Header>
		<div class="breadcrumbs wrap">
			<img src="../../assets/images/icon-home.png" alt="" class="breadcrumbs-icon" />
			<router-link :to="{ path: '/' }" style="color: #000;">首页</router-link> > 品牌分类 > <span
				style="color: #0887e0;">{{breadcrumbsName}}</span>
		</div>

		<div class="i-main wrap">

			<div class="class-top border-10">
				<img src="../../assets/images/class-bannerImg.png" alt="" />
			</div><!-- rank-m1 end -->

			<div class="rank-con clearfix mt-20">
				<div class="classify-left fl border-10 box-shadow">
					<ul class="classify-left-list">
						<li v-for="(item,index) in leftList" :key="index" :class="leftIndex == index?'active':''"
							@click="leftClick(index,item)">
							{{item.industryName}}
						</li>
					</ul>
				</div><!-- classify-left end -->
				<div class="classify-right fr border-10">
					<div class="classify-right-box" v-for="(item,a) in rightList1" :key="a">
						<div class="classify-right-title">
							<h3><i></i>{{item.industryName}}</h3>
						</div>
						<div class="classify-right-ul">
							<ul>
								<li v-for="(item2,b) in item.thirdLevelCategoryList" :key="b"
									@click="fenleiLink(item2)">
									<router-link :to="{ path: '/ranking', query: { industryId: Number(item2.industryId)+9999} }" >
									{{item2.industryName}}
									</router-link>
								</li>
							</ul>
						</div>
					</div><!-- classify-right-box end -->
				</div><!-- classify-right end -->
			</div><!-- rank-con end -->
		</div><!-- i-main end  -->
		<div class="i-m9-wrap border-top-1 mt-40">
			<div class="i-m9 wrap clearfix">
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_03.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>全球品牌总数</h4>
						<p>300000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_06.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>城市精选商家</h4>
						<p>100000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_09.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>行业品牌榜单</h4>
						<p>5000<em>+</em></p>
					</div>
				</div>
			</div>
		</div><!-- i-m9-wrap end -->

		<Footer></Footer>

		<el-backtop style="width:50px;height:50px;">
			<div style="{
          height: 100%;
          width: 100%;
          background: #da0505 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }">
				<img src="../../assets/images/backtop.png" alt="" style="vertical-align: top;">
			</div>
		</el-backtop>


	</div>
</template>

<script>
	import {
		initLeft,
		initRight
	} from "../../api/classify.js";
	import Footer from "@/components/footer";
	import Header from "@/components/header";
	export default {
		name: "category",
		components: {
			Header,
			Footer
		},
		data() {
			return {
				leftIndex: 0,
				leftList: [],
				rightList1: [],
				breadcrumbsName: '',
			};
		},
		created() {
			this.initPage();
		},
		computed: {},
		mounted() {},
		methods: {
			initPage() {
				this.getLeft()

			},
			//左侧菜单 初始
			getLeft() {
				initLeft({
					industryLevel: 1
				}).then(res => {
					console.log(res.data.data, '左侧菜单');
					this.leftList = res.data.data
					this.breadcrumbsName = res.data.data[0].industryName

					document.querySelector('meta[name="keywords"]').setAttribute('content', res.data.data[0]
						.pageKeywords);
					document.querySelector('meta[name="description"]').setAttribute('content', res.data.data[0]
						.pageDesc);
					document.title = res.data.data[0].pageTitle


					const id = res.data.data[0].industryId
					const parentId = Number(id) + 9999
					initRight({
						industryLevel: 2,
						parentId: parentId
					}).then(res => {
						console.log(res.data.data, '右侧菜单');
						this.rightList1 = res.data.data
					})
				})

			},

			//左侧菜单 切换
			leftClick(index, item) {
				// console.log(e.target.dataset.index)	
				// const index = e.target.dataset.index
				this.breadcrumbsName = item.industryName
				console.log(index, item, '菜单切换')
				this.leftIndex = index

				document.querySelector('meta[name="keywords"]').setAttribute('content', this.leftList[index].pageKeywords);
				document.querySelector('meta[name="description"]').setAttribute('content', this.leftList[index].pageDesc);
				document.title = this.leftList[index].pageTitle

				const parentId = Number(item.industryId) + 9999

				initRight({
					industryLevel: 2,
					parentId: parentId
				}).then(res => {
					console.log(res.data.data, '右侧菜单');
					this.rightList1 = res.data.data
				})


			},


			//跳转链接
			fenleiLink(item) {
				console.log(item, '跳转');
				var ID = item.industryId
				var id = Number(ID) + 9999
				var parentId = item.parentId
				this.$router.push({
					path: '/ranking',
					query: {
						industryId: id
					},
				});
			},

		},
	}
</script>

<style scoped>
	@import "../../assets/css/classify.css";
</style>