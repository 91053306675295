<template>
	<div class="container">
		<Header></Header>
		<div class="breadcrumbs wrap" v-if="header.brandSummary == null">
			<img src="../../assets/images/icon-home.png" alt=""  class="breadcrumbs-icon"/>
			<router-link :to="{ path: '/' }" style="color: #000;">首页</router-link> > 品牌 > {{header.brandName}}
		</div>
		
		<div class="detail2-top-wrap" v-if="header.brandSummary != null">
			<div class="detail2-top wrap clearfix">
				
				<div class="detail2-top-left fl">
					<div class="detail2-top-left-dw"></div>
					<div class="detail2-top-left-img">
						<img :src="imgUrl+header.brandLogo" alt="" />
					</div>
					<div class="detail2-top-left-v">
						<img src="../../assets/images/detail2-v.png" alt="" />
					</div>
					<a  :href="header.enterWebsite?'https://'+header.enterWebsite:'https://www.baidu.com'" class="detail2-top-left-btn mt-25" target="_blank">进入官网<img src="../../assets/images/icon-arrow-bai.png" alt="" /></a>
					<div class="mt-25 detail2-top-left-low">
						<span class="detail-top1r-tag">
							<img src="../../assets/images/detail-top-bq1.png" alt="" />
							<img src="../../assets/images/detail-top-bq2.png" alt="" />
							<img src="../../assets/images/detail-top-bq3.png" alt="" />
							<img src="../../assets/images/detail-top-bq4.png" alt="" />
						</span>
					</div>
					
				</div><!-- detail2-top-left end -->
				<div class="detail2-top-right fr">
					<div class="detail-left-hd">
						<a href="#ppjs" @click="detailNavClick(0)" :class="detailNavIndex==0?'active':''">品牌介绍</a>
						<a href="#xgwd" @click="detailNavClick(1)" :class="detailNavIndex==1?'active':''" v-if="this.wangdian.length>0">相关网店</a>
						<a href="#xgpp" @click="detailNavClick(2)" :class="detailNavIndex==2?'active':''" v-if="pinpai.length>1">相关品牌</a>
						<a href="#zsjm" @click="detailNavClick(3)" :class="detailNavIndex==3?'active':''" v-if="this.zhaoshang !==null">招商加盟</a>
						<a href="#qyxx" @click="detailNavClick(4)" :class="detailNavIndex==4?'active':''">企业信息</a>
					</div><!-- detail-left-hd end -->
					<div class="detail2-top-rCon  mt-20 clearfix">
						<div class="detail2-top-info fl">
							<div class="detail-top1-c1 clearfix">
								<h4 class="fl">{{header.brandName}}</h4>
								<div class="detail-top1-c2 fl">
									<img src="../../assets/images/icon-phone-blue.png" alt="" />
									<font v-if="header.brandTel != null && header.brandTel != ''">{{header.brandTel}}</font>
									<font v-else>{{header.enterTelphone}}</font>
								</div>
							</div>
							
							<div class="detail2-top-info-tb">
								<div class="detail-top2">
									<p><span>{{header.enterName}}</span> <img src="../../assets/images/icon-gongsi.png" class="icon-gongsi"/></p>
									<p><span>品牌创立时间： </span>{{header.establishdate}}</p>
									<p><span>注册金额： </span>{{header.regcapital}}</p>
									<p><span>品牌发源地： </span>{{header.brandBirth}}</p>
									<p style="width: 100%; margin-right: 0; height: 30px;overflow: hidden;"><span>总部地址： </span><img src="../../assets/images/icon-address-blue.png"/>{{header.detailaddress}}</p>
									<!-- <p><span>关注指数： </span><img src="../../assets/images/icon-zhishu.png"/>{{header.totalVisits}} 人</p> -->
									<p v-if="header.mallShopList.length>0" style="width: 100%; margin-right: 0; height: 30px;overflow: hidden;">
										<span>品牌网店： </span> 
										<em v-for="(item,index) in header.mallShopList.slice(0,3)" :key="index">
											<a :href="item.promotionLink" target="_blank" class="detail2-top-shop"  v-if="item.promotionLink != null && item.promotionLink != ''">
												<img :src="imgUrl+item.shopIcon" alt="" />{{item.name}}
											</a>
											<a :href="item.shopUrl" target="_blank" class="detail2-top-shop" v-else>
												<img :src="imgUrl+item.shopIcon" alt="" />{{item.name}}
											</a>
										</em>
									</p>
								</div><!-- detail-top2 end -->
							</div>
						</div><!-- detail2-top-info end -->
						
						<div class="detail2-top-ry fr">
							<div class="detail2-top-ry-smaill">
								<h3 class="detail2-top-ry-h">
									<img src="../../assets/images/detail2-top-r-h3l.png"/>
									<span>品牌荣誉</span>
									<img src="../../assets/images/detail2-top-r-h3r.png"/>
								</h3>
								<div class="detail2-top-ry-txt" v-html="header.brandSummary"></div>
							</div>
							<div class="detail2-top-ry-big">
								<div class="detail2-top-ry-sroall">
									<div class="detail2-top-ry-txt" v-html="header.brandSummary"></div>
								</div>
							</div>
						</div><!-- detail2-top-ry end -->
					</div>
				</div><!-- detail2-top-right end -->
			</div>
		</div><!-- detail2-top-wrap end -->
		
		<div class="i-main wrap clearfix">
			<div class="detail-top border-10" v-if="header.brandSummary == null">
				<div class="detail-top1 clearfix">
					<div class="detail-top1-logo fl">
						<img :src="imgUrl+header.brandLogo" alt="" />
					</div>
					<div class="detail-top1-c fl">
						<div class="detail-top1-c1">
							<h4>{{header.brandName}}</h4>
							<div class="detail-top1-c1-shop" v-if="header.mallShopList.length>0">
								<span v-for="(item,index) in header.mallShopList.slice(0, 3)" :key="index">
									<a :href="item.promotionLink" target="_blank" v-if="item.promotionLink != null && item.promotionLink != ''">
										<img :src="imgUrl+item.shopIcon" alt="" />{{item.name}}
									</a>
									<a :href="item.shopUrl" target="_blank" v-else>
										<img :src="imgUrl+item.shopIcon" alt="" />{{item.name}}
									</a>
								</span>
							</div>
							
						</div>
						<div class="detail-top1-c2">
							<img src="../../assets/images/icon-phone-blue.png" alt="" />
							<font v-if="header.brandTel != null && header.brandTel != ''">{{header.brandTel}}</font>
							<font v-else>{{header.enterTelphone}}</font>
							<span>|</span>{{header.enterName}}
						</div>
					</div>
					<div class="detail-top1-r fr mt-20">
						<span class="detail-top1r-tag">
							<img src="../../assets/images/detail-top-bq1.png" alt="" />
							<img src="../../assets/images/detail-top-bq2.png" alt="" />
							<img src="../../assets/images/detail-top-bq3.png" alt="" />
							<img src="../../assets/images/detail-top-bq4.png" alt="" />
						</span>
						<a :href="header.enterWebsite?this.header.enterWebsite:'https://www.baidu.com'" class="detail-top1-r-go fr" target="_blank">进入官网</a>
					</div>
				</div><!-- detail-top1 end -->
				<div class="detail-top2">
					<p><span>品牌发源地：</span>{{header.brandBirth}}</p>
					<p><span>注册金额： </span>{{header.regcapital}}</p>
					<p><span>关注指数： </span><img src="../../assets/images/icon-zhishu.png"/>{{header.totalVisits}} 人</p>
					<p><span>创立时间： </span>{{header.establishdate}}</p>
					<p style="width: 66%;"><span>总部地址： </span><img src="../../assets/images/icon-address-blue.png"/>{{header.detailaddress}}</p>
				</div><!-- detail-top2 end -->
			</div><!-- detail-top end -->
			<div class="detail-con clearfix mt-15">
				<div class="detail-left fl border-10">
					<div class="detail-left-hd" v-if="header.brandSummary == null">
						<a href="#ppjs" @click="detailNavClick(0)" :class="detailNavIndex==0?'active':''">品牌介绍</a>
						<a href="#xgwd" @click="detailNavClick(1)" :class="detailNavIndex==1?'active':''" v-if="this.wangdian.length>0">相关网店</a>
						<a href="#xgpp" @click="detailNavClick(2)" :class="detailNavIndex==2?'active':''" v-if="pinpai.length>1">相关品牌</a>
						<a href="#zsjm" @click="detailNavClick(3)" :class="detailNavIndex==3?'active':''" v-if="this.zhaoshang !==null">招商加盟</a>
						<a href="#qyxx" @click="detailNavClick(4)" :class="detailNavIndex==4?'active':''">企业信息</a>
					</div><!-- detail-left-hd end -->
					<div class="detail-left-body">
						<div class="detail-l1" id="ppjs">
							<div class="detail-left-tit" v-if="header.brandSummary != null">
								<span>品牌介绍</span>
							</div>
							<div class="detail-l1-pic">
								<img :src="imgUrl+header.brandImage" alt="" />
							</div>
							<div class="detail-l1-text" :class="textShow1?'textShow1':''">
								<div v-html="formattedData"></div>
							</div>
							
							<div class="detail-moreBtn mt-30" @click="textShowBtn1">
								<span v-if="!textShow1">更多</span>
								<span v-else>收起</span>
								<img src="../../assets/images/icon-add.png" alt="" />
							</div>	
						</div><!-- detail-l1 end -->
						<div class="detail-l2" v-if="this.wangdian.length>0" id="xgwd">
							<div class="detail-left-tit">
								<span>相关网店</span>
							</div>
							<div class="detail-l2-listbox mt-25">
								<ul class="detail-l2-list clearfix" :class="textShow2?'textShow2':''">
									<li v-for="(item,a) in wangdian" :key="a">
										<div class="detail-l2-list-img">
											<img :src="imgUrl+item.shopImages[0]" alt="" />
										</div>
										<div class="detail-l2-list-info">
											<h4>{{item.name}}</h4>
											<div class="detail-l2-list-low clearfix">
												<a class="detail-l2-list-link fr" @click="linkShop(item)">进店></a>
												<span class="ml-0"><img :src="imgUrl+item.shopIcon" alt="" />{{item.shopIconName}}</span>
												<span><img :src="imgUrl+item.shopTypeIcon" alt="" />{{item.shopTypeIconName}}</span>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div class="detail-moreBtn" @click="textShowBtn2" v-if="this.wdDataCon.length>4">
								<span v-if="wdDataCon.length== wangdian.length">已经到底了</span>
								<span v-else>更多</span>
								<img src="../../assets/images/icon-add.png" alt="" />
							</div>	
						</div><!-- detail-l2 end -->
						<div class="detail-l3 mt-25" id="xgpp" v-if="pinpai.length>1">
							<div class="detail-left-tit">
								<span>相关品牌</span>
							</div>
							<ul class="detail-l3-list" :class="textShow3?'textShow3':''">
								<li v-for="(item,b) in pinpai" :key="b" @click="xgppLink(item)">
									<div class="detail-l3-list-img">
										<img :src="imgUrl+item.brandLogo" alt="" />
									</div>
									<div class="detail-l3-list-info">
										<a>
											<h4>{{item.brandName}}</h4>
											<p>{{item.brandSummary}}</p>
										</a>
									</div>
								</li>
							</ul>
							<div class="detail-moreBtn mt-25" @click="textShowBtn3" v-if="ppDataCon.length>3">
								<span v-if="ppDataCon.length== pinpai.length">已经到底了</span>
								<span v-else>更多</span>
								
								<img src="../../assets/images/icon-add.png" alt="" />
							</div>
						</div><!-- detail-l3 end -->
						<div class="detail-l4 mt-25" id="zsjm" v-if="this.zhaoshang !==null">
							<div class="detail-left-tit">
								<span>招商加盟</span>
							</div>
							<div class="detail-l4-con">
								<dl class="detail-l4-dl">
									<dt>品牌名称：</dt>
									<dd>{{zhaoshang.brandName}}</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>经营模式：</dt>
									<dd>{{zhaoshang.busmodel}}</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>发展模式：</dt>
									<dd>{{zhaoshang.devmodel}}</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>适合人群：</dt>
									<dd>{{zhaoshang.suitablePeople}}</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>基本投资：</dt>
									<dd>{{zhaoshang.franchiseFund}}</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>招商电话：</dt>
									<dd>{{zhaoshang.investmenttel}}</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>加盟行业：</dt>
									<dd>{{zhaoshang.franchiseIndustry}}</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>门店总数	：</dt>
									<dd>{{zhaoshang.totalStores}}</dd>
								</dl>
							</div>
						</div><!-- detail-l4 end -->
						<div class="detail-l5 mt-45" id="qyxx">
							<div class="detail-left-tit">
								<span>企业信息</span>
							</div>
							<div class="detail-l5-con clearfix">
								<div class="detail-l5-table" :class="tableIndex % 2 !=0?'odd':''">
									<dl class="detail-l5-dl">
										<dt><span>企业名称</span></dt>
										<dd><p>{{info.enterName.length!=null?info.enterName:'无'}}</p></dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>统一社会信用代码</dt>
										<dd><p>{{info.unifiedscc!=null?info.unifiedscc:'无'}}</p></dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>法定代表人</dt>
										<dd><p>{{info.enterLegalperson!=null?info.enterLegalperson:'无'}}</p></dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>企业邮箱</dt>
										<dd><p>{{info.enterMail!=null?info.enterMail:'无'}}</p></dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>成立日期</dt>
										<dd><p>{{info.enterStablishdate!=null?info.enterStablishdate:'无'}}</p></dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>注册资本</dt>
										<dd><p>{{info.regcapital!=null?info.regcapital:'无'}}</p></dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>企业类型</dt>
										<dd><p>{{info.enterType!=null?info.enterType:'无'}}</p></dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>工商注册号</dt>
										<dd><p>{{info.registrationNumber!=null?info.registrationNumber:'无'}}</p></dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>营业期限</dt>
										<dd><p>{{info.licenseValidity!=null?info.licenseValidity:'无'}}</p></dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>核准日期</dt>
										<dd><p>{{info.approvaldate!=null?info.approvaldate:'无'}}</p></dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>登记机关</dt>
										<dd><p>{{info.regauthority!=null?info.regauthority:'无'}}</p></dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>参保人数</dt>
										<dd><p>{{info.socialSecurity!=null?info.socialSecurity:'无'}}</p></dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>注册地址</dt>
										<dd><p>{{info.detailaddress!=null?info.detailaddress:'无'}}</p></dd>
									</dl>
								</div>
								<dl class="detail-l5-dl w100 detail-l5-dl-jyfw">
									<dt><span>经营范围</span></dt>
									<dd>
										<p :class="jyfwMore?'jyfwMore':''">{{info.businessscope}}</p>
										<a href="###" class="fr" @click="jyfwMoreBtn()"><span v-if="!fanweiShow">更多></span><span v-else>收起</span></a>
									</dd>
								</dl>
							</div>
							<div class="detail-l5-tx mt-35">
								<span class="el-icon el-icon-warning-outline"></span>
								提醒: 可能因企业资料变更未及时更新造成与实际登记有差别，请以国家部门核准登记的为准。
							</div>
						</div><!-- detail-l5 end -->
						<div class="article-l4-zanbox">
							<div  class="article-l4-zan" @click="zanBtn">
								<span></span>
								<p>{{zanNum}}</p>
								<transition name="el-zoom-in-center">
								    <i v-if="numShow">+1</i>
								</transition>
							</div>
						</div>
					</div><!-- detail-left-body end -->
				</div><!-- detail-left end -->
				<div class="detail-right fr">
					<div class="detail-r-hy">
						<div class="detail-rTit">按行业查看</div>
						<div class="detail-r-hy-listBox">
							<ul class="detail-r-hy-list clearfix" :class="hySeeMore?'hySeeMore':''">
								<li v-for="(item,c) in hangyeSee" :key="c" @click="hyckLink(item)">
									<a>
										<span><img :src="imgUrl+item.industryTrans" alt="" /></span>
										<p>{{item.industryName}}</p>
									</a>
								</li>
							</ul>
							<div class="detail-rMore mt-30" v-if="hangyeSee.length>12">
								<a  @click="hySeeMoreBtn()" v-if="!this.hySeeMore">查看更多 <img src="../../assets/images/icon-arrow-more.png"/></a>
								<a  @click="hySeeMoreBtn()" v-else>收起更多 <img src="../../assets/images/icon-arrow-more.png"/></a>
							</div>
						</div>
					</div>
					<div class="detail-r-pp" v-if="this.pinpaiSee.length>0">
						<div class="detail-rTit">按品牌查看</div>
						<div class="detail-r2-list">
							<ul>
								<li v-for="(item,index) in pinpaiSee" :key="index" @click="ppSeeLink(item)">
									<a>
										<img :src="imgUrl+item.brandLogo" alt="" />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div><!-- detail-right end -->
			</div><!-- detail-con end -->
		</div><!-- i-main end  -->
		<div class="i-m9-wrap mt-40">
			<div class="i-m9 wrap clearfix">
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_03.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>全球品牌总数</h4>
						<p>300000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_06.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>城市精选商家</h4>
						<p>100000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_09.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>行业品牌榜单</h4>
						<p>5000<em>+</em></p>
					</div>
				</div>
			</div>
		</div><!-- i-m9-wrap end -->		
		<Footer></Footer>
		<el-backtop style="width:50px;height:50px;">
			<div style="{
          height: 100%;
          width: 100%;
          background: #da0505 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }">
				<img src="../../assets/images/backtop.png" alt="" style="vertical-align: top;">
			</div>
		</el-backtop>
	</div>
</template>

<script>
	import {header,info,zhaoshang,pinpai,wangdian,hangyeSee,pinpaiSee} from "../../api/brand.js";
	import Footer from "@/components/footer";
	import Header from "@/components/header";
	export default {
		name: "brandDetail",
		components: {
			Header,
			Footer
		},
		data() {
			return {
				imgUrl:'http://zgall.com/prod-api',
				detailNavIndex:0,//导航
				textShow1:false,//控制更多显示
				textShow2:false,//控制更多显示
				textShow3:false,//控制更多显示
				jyfwMore:false,
				hySeeMore:false,
				fanweiShow:false,//经营范围
				tableIndex:9,//表格数量
				brandId:'33',//品牌id
				header:{},
				info:{},//企业信息
				zhaoshang:{},//招商
				wangdian:[],//网店
				pinpai:[],//品牌
				hangyeSee:[],
				pinpaiSee:[],
				zanNum:'',
				numShow:false,
				wdDataCon:[],//网店总数
				wdNum: 4,//网店默认显示条数
				
				ppDataCon:[],//品牌总数
				ppNum: 3,//品牌默认显示条数
				
				pageTitle:'品牌之家',
				pagekeywords:'',
				pagedesc:'',
				industryId:'',//行业id
			};
		},
	
		created() {
			const brandId = this.$route.query.brandId; // 获取参数值
			const industryId = this.$route.query.industryId // 获取参数值
			console.log('接收brandId',brandId); // 输出参数品牌id
			console.log('接收industryId',industryId); // 输出参数行业id
			this.brandId = brandId
			this.industryId = industryId
			
			this.initPage();
		},
		computed: {
		    // formattedData() {
		    //   // 去掉p标签并添加换行符
		    //   return this.header.brandContent.replace(/<(\/)?p>/g, '\n');
		    // },
			formattedData() {
			  // 去掉p标签并添加换行符
			  return this.header.brandContent.replace(/\n/g, '<br>');
			},
		},
		mounted() {
			document.title = this.pageTitle;
			document.querySelector('meta[name="keywords"]').setAttribute('content',  this.pagekeywords);
			document.querySelector('meta[name="description"]').setAttribute('content',  this.pagedesc);
		},
		methods: {
			initPage(){
				this.getHeader()
				this.getInfo()
				this.getZhaoshang()
				this.getPinpai()
				this.getWangdian()
				this.getHangyeSee()
				this.getPinpaiSee()
			},
			
			linkShop(item){
				var tuiguang = item.promotionLink
				var link = item.shopUrl
				if (tuiguang==''|| tuiguang == null) {
					window.open(link);
					console.log(1)
				} else{
					window.open(tuiguang);
					console.log(2)
				}
			},
			
			guanwangLink(){
				var url = this.header.enterWebsite
				if (url==''|| url == 'null') {
					url = 'https://www.baidu.com'
				} 
				console.log(url,'guianwang00000000000')
				// window.location.href = 'www.siemens.com/cn/zh.html';
				window.open(url,'_blank');
			},
			
			
			//左侧导航
			detailNavClick(index){
				this.detailNavIndex = index
			},

			//点赞
			zanBtn(){
				this.zanNum = this.zanNum+1
				this.numShow = true
				var that = this
				setTimeout(function(){
					// alert(1111)
					that.numShow = false
				},2000)
			},
			//更多展开
			textShowBtn1(){
				this.textShow1 = !this.textShow1
			},

			textShowBtn2(){
				this.textShow2 = true
				this.wdNum += 4;
				this.getWangdian()
			},

			textShowBtn3(){
				this.textShow3 = true
				this.ppNum += 2;
				this.getPinpai()
			},

			jyfwMoreBtn(){
				this.jyfwMore = !this.jyfwMore
				this.fanweiShow = !this.fanweiShow 
			},
			
			hySeeMoreBtn(){
				this.hySeeMore = !this.hySeeMore
			},
			//相关品牌跳转
			xgppLink(item){
				console.log(item,'相关品牌跳转')
				this.brandId =  Number(item.brandId)+9999
				var id = Number(item.brandId)+9999
				this.$router.push({
					path: '/brand/detail',
					query: {
						brandId: id,
					},
				});
				this.initPage()
				
			},
			//按品牌查看跳转
			ppSeeLink(item){
				console.log(item,'按品牌查看跳转')
				this.brandId = Number(item.brandId)+9999
				this.industryId = Number(item.industryId)+9999
				this.$router.push({
					path: '/brand/detail',
					query: {
						brandId: this.brandId,
					},
				});
				this.initPage()
			},
			
			
			//行业查看跳转
			hyckLink(item){
				console.log(item,'行业查看跳转')
				var id = Number(item.industryId)+9999
				this.$router.push({
					name: 'ranking',
					query: {industryId: id},
				});
			},
			
			//头部
			getHeader(){
				console.log(this.brandId)
				header(this.brandId).then(res=>{
					console.log(res.data.data,'头部');
					this.header=res.data.data
					this.pageTitle = res.data.data.pagetitle
					this.pagekeywords = res.data.data.pagekeywords
					this.pagedesc = res.data.data.pagedesc
					
					document.querySelector('meta[name="keywords"]').setAttribute('content', res.data.data.pagekeywords);
					document.querySelector('meta[name="description"]').setAttribute('content', res.data.data.pagedesc);
					document.title = res.data.data.pagetitle
					
					var num = res.data.data.likes
					if (num==null || num == '') {
						this.zanNum = 0
					} else{
						this.zanNum=res.data.data.likes
					}
					
				})
			},

			//企业信息
			getInfo(){
				info(this.brandId).then(res=>{
					console.log(res.data.data,'企业信息');
					this.info=res.data.data
				})
			},
			
			//招商加盟
			getZhaoshang(){
				zhaoshang(this.brandId).then(res=>{
					console.log(res.data.data,'招商加盟');
					this.zhaoshang = res.data.data
				})
			},


			//相关网店
			getWangdian(){
				wangdian(this.brandId).then(res=>{
					console.log(res.data.data,'网店');
					// this.wdDataCon = res.data.data
					var arr = res.data.data
					var newArr = arr.map(function(item) {
					    var newItem = { ...item };  
					    newItem.shopImages = []; 
						
					    if (item.shopImages && item.shopImages !== "" && item.shopImages != null) {  
					        newItem.shopImages = item.shopImages.split(",");  
					    }  
						
					    return newItem;  
					});
					// console.log(newArr,'22222222');
					
					this.wdDataCon = newArr
					
					if (this.wdDataCon.length > 4) {
						this.wangdian = this.wdDataCon.slice(0, this.wdNum);
					} else {
						this.wangdian = this.wdDataCon
					}
				
				})
			},

			//相关品牌
			getPinpai(){
				pinpai(this.brandId).then(res=>{
					console.log(res.data.data,'品牌');
					this.ppDataCon = res.data.data
					if (this.ppDataCon.length > 3) {
						this.pinpai = this.ppDataCon.slice(0, this.ppNum);
					} else {
						this.pinpai = this.ppDataCon
					}
					
				})
			},

			//行业查看
			getHangyeSee(){
				hangyeSee(this.brandId).then(res=>{
					console.log(res.data.data,'行业查看');
					this.hangyeSee = res.data.data
				})
			},	
		

			//品牌查看
			getPinpaiSee(){
				pinpaiSee({
					brandId:this.brandId,
					industryId:this.industryId
				}).then(res=>{
					console.log(res.data.data,'品牌查看');
					this.pinpaiSee = res.data.data
				})
			},


			//跳转链接
			m8arrowLink() {
				this.$router.push({
					path: '/project-detail/project-detail'
				});
			},
			
			// 行业排行-更多列表
			moreBtn() {
				this.$router.push({
					path: '/rankingMore/rankingMore'
				});
			},
				

		},
	}
</script>

<style scoped>
	@import "../../assets/css/detail.css";
</style>