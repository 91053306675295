<template>
	<div class="container">
		<Header></Header>
		<div class="rank-banner-wrap">
			<div class="rank-banner wrap">
				<div class="rank-banner-left fl">
					<div class="rank-banner-left-title">
						<h2>{{head.mainTitle}}</h2>
					</div>
					<div class="rank-banner-left-info">
						<div class="rank-banner-left-infoItem">
							<img src="../../assets/images/rank-banner-icon1.png" alt="" class="rank-banner-left-icon" />
							<div class="rank-banner-left-infoTxt">
								<h4>{{head.totalVisits}}</h4>
								<p>关注人数</p>
							</div>
						</div>
						<div class="rank-banner-left-infoItem">
							<img src="../../assets/images/rank-banner-icon2.png" alt="" class="rank-banner-left-icon"/>
							<div class="rank-banner-left-infoTxt">
								<h4>{{head.brandNumber}}</h4>
								<p>参与品牌</p>
							</div>
						</div>
					</div>
					<div class="rank-banner-left-step">
						<div class="rank-banner-left-stepItem">
							<h4>初选公布</h4>
							<p><img src="../../assets/images/icon-arrow-yuan-yellow.png" alt="" /></p>
						</div>
						<div class="rank-banner-left-stepItem">
							<h4><img src="../../assets/images/rank-banner-link.png" alt="" class="rank-banner-left-stepItem-line"/></h4>
							<p>&nbsp;</p>
						</div>
						<div class="rank-banner-left-stepItem">
							<h4>公示期</h4>
							<p>国民监督</p>
						</div>
						<div class="rank-banner-left-stepItem">
							<h4><img src="../../assets/images/rank-banner-link.png" alt="" class="rank-banner-left-stepItem-line"/></h4>
							<p>&nbsp;</p>
						</div>
						<div class="rank-banner-left-stepItem">
							<h4>正式发布</h4>
							<p>{{head.updateTime}}</p>
						</div>
					</div>
				</div>
				<div class="rank-banner-right fr">
					<img src="../../assets/images/rank-banner-right.png" alt="" />
				</div>
			</div><!-- rank-banner end -->
		
		</div>
		<!-- banner-wrap end -->
		<div class="breadcrumbs wrap">
			<img src="../../assets/images/icon-home.png" alt=""  class="breadcrumbs-icon"/>
			<router-link :to="{ path: '/' }" style="color: #000;">首页</router-link> > 品牌排行 > {{head.parentName}} > <span style="color: #0887e0;">{{head.industryName}}</span>
		</div>
		
		<div class="i-main wrap">
			
			<div class="rank-m1 border-10">
				<h3>{{head.pageSubtitle}}</h3>
				<P>{{head.pageDesc}}</P>
				<div class="rank-m1-img">
					<img :src="imgUrl+head.industryTrans" alt=""/>
				</div>
			</div><!-- rank-m1 end -->
			
			<div class="rank-con clearfix mt-20">
				<div class="rank-left fl">
					<div class="rank-left-listBox">
						<ul class="rank-left-list">
							<li v-for="(item,a) in leftList10" :key="a">
								<router-link :to="{ path: '/brand/detail', query: { brandId: Number(item.brandId)+9999, industryId:industryId}} " >
									<i class="rank-left-list-icon"></i>
									<div class="rank-left-list-img">
										<img :src="imgUrl+item.brandLogo" alt="" />
									</div>
									<div class="rank-left-list-info">
										<div class="rank-left-list-infoTop">
											<h3>
												<span class="fl rank-left-list-info-tit">{{item.brandName}}</span>
												<span class="tag-red rank-left-list-tag ml-15" v-if="item.establishdate != null && item.establishdate !=''">{{item.establishdate}}</span>
												<span class="tag-blue rank-left-list-tag ml-5" v-if="item.brandBirth != null && item.brandBirth !=''">{{item.brandBirth}}</span>
											</h3>
											<p>{{item.enterName}}</p>
										</div>
										<div class="rank-left-list-info-shop">
											<span @click.stop="shopLink(item2)" class="rank-left-list-info-shopSpan" v-for="(item2,i) in item.mallShopList" :key="i">
												<img :src="imgUrl+item2.shopIcon" alt="" />
												{{item2.name}}
											</span>
										</div>
									</div>
									<div class="rank-left-list-zhf">
										<span>{{item.score}}</span>
									</div>
								</router-link>
							</li>
						</ul>
					</div><!-- rank-left-listBox end -->
					<div class="rank-left-hot border-10" v-if="this.leftList.length > 10">
						<div class="rank-left-hot-title">
							<h2><span>热门{{head.industryName}}品牌</span></h2>
						</div>
						
						<ul class="rank-left-hot-list">
							<li v-for="(item,b) in leftList8" :key="b">
								<router-link :to="{ path: '/brand/detail', query: { brandId: Number(item.brandId)+9999, industryId:industryId}} " >
									<span class="rank-left-hot-list-img"><img :src="imgUrl+item.brandLogo" alt="" /></span>
									<p>{{item.brandName}}</p>
								</router-link>
							</li>
						</ul>
					</div><!-- rank-left-hot end -->
					<div class="rank-left-more border-10" @click="moreBtn">
						全部品牌排行 >
					</div>
				</div><!-- rank-left end -->
				<div class="rank-right fr">
					<div class="rank-r1 rank-right-box border-10">
						<div class="rank-right-title clearfix">
							<h3><img src="../../assets/images/icon-zuanshi.png" alt="" />行业关联榜单</h3>
						</div>
						<div class="rank-r1-list">
							<ul>
								<li v-for="(item,c) in guanlianList" :key="c" @click="hyglLink(item)">
									<router-link :to="{ path: '/ranking', query: { industryId: Number(item.industryId)+9999}} " >
										<span class="rank-r1-list-img"><img :src="imgUrl+item.industryTrans" alt="" /></span>
										<p>{{item.industryName}}</p>
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="rank-r1 rank-right-box border-10 mt-20" v-if="pinpaiList.length>0">
						<div class="rank-right-title clearfix">
							<h3><img src="../../assets/images/icon-huangguan.png" alt="" />十大品牌</h3>
						</div>
						<div class="rank-r2-list">
							<ul>
								<li v-for="(item,d) in pinpaiList" :key="d">
									<router-link :to="{ path: '/brand/detail', query: { brandId: Number(item.brandId)+9999, industryId:industryId}} " >
										<img :src="imgUrl+item.brandLogo" alt="" />
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="rank-r1 rank-right-box border-10 mt-20">
						<div class="rank-right-title clearfix">
							<h3><img src="../../assets/images/icon-maozi.png" alt="" />品牌百科</h3>
						</div>
						<div class="rank-r3-list">
							<ul>
								<li v-for="(item,e) in baikeList" :key="e" >
									<i>{{e+1}}</i>
									<router-link :to="{ path: '/articleDetail', query: { id: Number(item.id)+9999}} " >
										{{item.title}}
									</router-link>
								</li>
							</ul>
						</div>
					</div>
				</div><!-- rank-right end -->
			</div><!-- rank-con end -->
		</div><!-- i-main end  -->
		<div class="i-m9-wrap mt-40 border-top-1">
			<div class="i-m9 wrap clearfix">
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_03.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>全球品牌总数</h4>
						<p>300000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_06.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>城市精选商家</h4>
						<p>100000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_09.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>行业品牌榜单</h4>
						<p>5000<em>+</em></p>
					</div>
				</div>
			</div>
		</div><!-- i-m9-wrap end -->		
			
		<Footer></Footer>
		
		<el-backtop style="width:50px;height:50px;">
			<div style="{
          height: 100%;
          width: 100%;
          background: #da0505 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }">
				<img src="../../assets/images/backtop.png" alt="" style="vertical-align: top;">
			</div>
		</el-backtop>
		
		
	</div>
</template>

<script>
	import {getHead,getList,guanlian,pinpai,baike} from "../../api/ranking.js";
	import Footer from "@/components/footer";
	import Header from "@/components/header";
	export default {
		name: "ranking",
		components: {
			Header,
			Footer
		},
		data() {
			return {
				imgUrl:'http://zgall.com/prod-api',
				industryId:'',//接收id
				leftList:[],
				leftList8:[],
				leftList10:[],
				head:{},//头部	
				guanlianList:[],
				pinpaiList:[],
				baikeList:[],
				pageTitle:'品牌之家',
			};
		},
	
		created() {
			const industryId = this.$route.query.industryId; // 获取参数值
			console.log('接收industryId',industryId); // 输出参数值id
			this.industryId = industryId
			this.initPage();
		},
		computed: {},
		mounted() {
			document.title = this.pageTitle;
		},
		methods: {
			initPage(){
				this.getBanner()
				this.getLeftList()
				this.getGuanlian()
				this.getPinpai()
				this.getBaike()
			},
			
			//首页头部
			getBanner(){
				getHead(this.industryId).then(res=>{
					console.log(res.data.data,'头部');
					this.head = res.data.data
					this.pageTitle = res.data.data.pageTitle
					document.title = this.pageTitle;
					document.querySelector('meta[name="keywords"]').setAttribute('content', res.data.data.pageKeywords);
					document.querySelector('meta[name="description"]').setAttribute('content', res.data.data.pageDesc);
				})
			},

			//品牌列表
			getLeftList(){
				getList({
					industryId:this.industryId,
					pageSize:18,
				}).then(res=>{
					console.log(res.data.rows,'品牌列表');
					this.leftList = res.data.rows
					this.leftList10 = this.leftList.slice(0,10)
					this.leftList8 = this.leftList.slice(-8)
				})
			},

			//行业关联
			getGuanlian(){
				guanlian(this.industryId).then(res=>{
					console.log(res.data.data,'关联');
					this.guanlianList = res.data.data
				})
			},
			
			//十大品牌
			getPinpai(){
				pinpai({
					industryId:this.industryId,
				}).then(res=>{
					console.log(res.data.data,'十大品牌');
					this.pinpaiList = res.data.data
				})
			},


			//百科
			getBaike(){
				baike().then(res=>{
					console.log(res.data.data,'百科');
					var dataCon = res.data.data
					this.baikeList = dataCon.slice(0, 8);
				})
			},

			// 行业排行-更多列表
			moreBtn() {
				var id = this.industryId
				this.$router.push({
					path: '/rankingMore',
					query:{industryId: id}
				});
				 window.scrollTo(0, 0)
			},
			
			ppLink(item){
				console.log(item,'品牌跳转');
				var id = Number(item.brandId)+9999
				this.$router.push({
					path: '/brand/detail',
					query: {
						brandId: id,
						industryId:this.industryId
					},
				});
			},

			ppbkLink(item){
				console.log(item,'品牌百科跳转');
				var id = Number(item.id)+9999
				var channelName = item.channelName
				this.$router.push({
					path: '/articleDetail',
					query: {
						id: id,
						// channelName:channelName,
					},
				});
				
			},

			//跳转链接
			hyglLink(item) {
				console.log(item,'行业关联跳转');
				this.industryId = Number(item.industryId)+9999
				var id = this.industryId
				// this.$router.push({
				// 	path: '/ranking',
				// 	query: {industryId: id},
				// });
				this.initPage()
			},
			
			//十大品牌链接
			pinpaiLink(item){
				console.log(item,'十大品牌链接');
				var id =  Number(item.brandId)+9999
				this.$router.push({
					path: '/brand/detail',
					query: {
						brandId: id,
						industryId:this.industryId
					},
				});
				
			},
			shopLink(item){
				// console.log(item,'sddsfdsf');
				var tuiguang = item.promotionLink
				var link = item.shopUrl
				
				if (tuiguang==''|| tuiguang == null) {
					window.open(link);
					console.log(1)
				} else{
					window.open(tuiguang);
					console.log(2)
				}
			},
		},
	}
</script>

<style scoped>
	@import "../../assets/css/ranking.css";
</style>